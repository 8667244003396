<template>
    <div class="modal secondary prompt" v-if="modelValue">
        <div class="modal_container">
            <Form @submit="handleSubmit" @invalid-submit="handleFormInvalidSubmit" v-slot="{ errors }" ref="assign-contacts-form">
                <img src="@/assets/images/logo2.svg" alt="" class="logo_img">
                <div class="setting_wpr">
                    <div class="optin_type border-bottom">
                        <h4>Free <span>Your client will be redirected to built-in asset opt-in page.</span></h4>
                        <label for="free_teaser" class="switch_option capsule_btn">
                            <input type="radio" name="tease_type" id="free_teaser" :value="0" v-model="form.setting_value.is_premium" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="optin_type">
                        <h4>Paid <span>Your client will be redirected to the asset checkout page or a URL of your choice.</span></h4>
                        <label for="paid_teaser" class="switch_option capsule_btn">
                            <input type="radio" name="tease_type" id="paid_teaser" :value="1" v-model="form.setting_value.is_premium" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                    <div class="form_grp pt-5" v-if="form.setting_value.is_premium">
                        <div class="group_item">
                            <label class="input_label">Please select a redirect URL <a class="option" @click="form.setting_value.has_optin_url = !form.setting_value.has_optin_url">{{ form.setting_value.has_optin_url ? 'Add from list' : '+ Add URL' }}</a></label>
                            <template v-if="form.setting_value.has_optin_url">
                                <div class="field_wpr has_prefix" :class="{ 'has-error': errors.url }" v-if="form.setting_value.has_optin_url">
                                    <Field type="text" name="url" placeholder="https://thrivecoach.io/optin" v-model="form.setting_value.redirect_url" rules="required|url" label="redirect url" />
                                    <span class="prefix">URL</span>
                                </div>
                                <ErrorMessage name="url" class="text-danger" />
                            </template>
                            <template v-if="!form.setting_value.has_optin_url">
                                <div class="field_wpr" :class="{ 'has-error': errors.checkout_product }">
                                    <Field autocomplete="off" name="checkout_product" v-model="form.setting_value.checkout_product" rules="required_int" label="checkout product">
                                        <multiselect
                                            v-model="form.setting_value.checkout_product"
                                            label="name"
                                            value-prop="id"
                                            placeholder="Choose a product"
                                            :options="liveProducts"
                                            :searchable="true"
                                            trackBy="search_key"
                                        ></multiselect>
                                    </Field>
                                </div>
                                <ErrorMessage name="checkout_product" class="text-danger" />
                            </template>
                        </div>
                    </div>
                </div>
                <div class="action_wpr mt-5">
                    <button type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                    <button class="btn save_btn" :disabled="teaseSettingsUpdateLoaderSatatus">
                        <template v-if="teaseSettingsUpdateLoaderSatatus">
                            <i class="fa fa-spin fa-spinner"></i> Saving
                        </template>
                        <template v-else>
                            Tease
                        </template>
                    </button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import { Field, Form, ErrorMessage } from 'vee-validate'

import Multiselect from '@vueform/multiselect'
import Swal from 'sweetalert2'
import Helper from '@/utils/Helper'

export default {
    data () {
        return {
            form: {
                id: 0,
                enabled: 1,
                setting_value: {
                    is_premium: 0,
                    has_optin_url: false,
                    checkout_url: '',
                    checkout_product: '',
                    redirect_url: '',
                },
            },
            isMounted: false,
        };
    },

    props: {
        modelValue: Boolean,
        service: String,
        asset: Object,
    },

    emits: ['update:modelValue'],

    watch: {
        modelValue (val) {
            const vm = this;

            vm.isMounted = false;

            if (val) {
                vm.form.id = vm.asset.tease_setting.id;
                vm.form.setting_value = JSON.parse(JSON.stringify(vm.asset.tease_setting.setting_value));

                vm.getLiveProducts();

                if (!Object.keys(vm.companySettings).length) {
                    vm.getCompanySettings();
                }

                setTimeout(() => {
                    vm.isMounted = true;
                }, 1000);
            }
        },

        'form.setting_value.checkout_product' (productId) {
            const vm = this;

            if (productId) {
                const product = vm.liveProducts.filter(product => product.id == productId)[0];

                if (product) {
                    vm.form.setting_value.checkout_url = 'https://' + vm.companySettings.subdomain + '.' + process.env.VUE_APP_CHECKOUT_URL + '/' + product.slug;
                }
            }
        },

        'form.setting_value.is_premium' (val) {
            const vm = this;

            if (val && vm.isMounted) {
                vm.handlePaidTeasePopup();
            }
        }
    },

    components: {
        Multiselect,
        Field,
        Form,
        ErrorMessage,
    },

    computed: mapState({
        liveProducts: state => state.checkoutModule.liveProducts,
        liveProductLoader: state => state.checkoutModule.liveProductLoader,
        companySettings: state => state.checkoutModule.companySettings,
        teaseSettingsUpdateLoaderSatatus: state => state.teaseModule.teaseSettingsUpdateLoaderSatatus,
    }),

    methods: {
        ...mapActions({
            getLiveProducts: 'checkoutModule/getLiveProducts',
            getCompanySettings: 'checkoutModule/getCompanySettings',
            updateTease: 'teaseModule/updateTease',
        }),

        closeModal () {
            const vm = this;

            vm.$emit('update:modelValue', false);
        },

        handleSubmit (form, { setFieldError }) {
            const vm = this;

            const params = JSON.parse(JSON.stringify(vm.form));
            params.setFieldError = setFieldError;

            vm.updateTease(params).then((result) => {
                if (result) {
                    vm.closeModal();
                    vm.resetForm();
                }
            });
        },

        resetForm () {
            const vm = this;

            vm.form = {
                id: 0,
                enabled: 1,
                setting_value: {
                    is_premium: 0,
                    has_optin_url: false,
                    checkout_url: '',
                    checkout_product: 0,
                    redirect_url: '',
                }
            };
        },

        handlePaidTeasePopup () {
            const options = Helper.swalWarningOptions(' ', 'In order to use the paid tease feature please make sure that your product is created in checkouts, and that a fulfillment journey is active. Then paste the active checkout URL into the checkout field and hit Tease.');

            Swal.fire(options);
        },
    }
}
</script>

<style scoped>
    .modal.prompt .close_btn {
        position: absolute;
        right: -7px;
        left: auto;
        top: -7px;
        border: 1px solid #c7c7c7;
        background: rgba(50, 55, 59, 0.7);
        color: #fff;
        border-radius: 50%;
        font-size: 11px;
        width: 22px;
        height: 22px;
        cursor: pointer;
        z-index: 1;
    }

    .modal.prompt .logo_img {
        max-height: 30px;
        width: auto;
        margin-bottom: 30px;
    }

    .modal.prompt .setting_wpr {
        max-width: 400px;
        width: 100%;
    }

    .modal.prompt .optin_type {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 0;
    }

    .modal.prompt h4 {
        font-size: 17px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        margin-right: 15px;
    }

    .modal.prompt h4 span {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        display: block;
        padding-top: 3px;
    }

    .modal.prompt .action_wpr .btn.cancel_btn {
        background: #fff;
    }
</style>